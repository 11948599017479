<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
</template>

<style>
.not-found {
  text-align: center;
  margin-top: 100px;
}

h1 {
  font-size: 36px;
}

p {
  font-size: 18px;
  margin-bottom: 20px;
}

router-link {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}
</style>
