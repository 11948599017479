<template>
  <div>
    <div v-if="hasQueuePosition && !entryFailed">
      Your counter number is {{ myPosition }}
    </div>
    <div v-if="entryFailed">An error occurred while entering the line</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axiosClient from "../utils/axios";

export default {
  name: "MyPosition",
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["hasRequestId", "hasQueuePosition", "hasToken"]),
    // properties for vuex state
    requestId() {
      return this.$store.state.requestId;
    },
    myPosition() {
      return this.$store.state.myPosition;
    },
  },
  data() {
    return {
      entryFailed: false,
    };
  },
  mounted() {
    if (!this.hasRequestId) {
      this.requestQueuePosition();
    } else {
      this.retrieveLinePosition();
    }
    this.$watch("hasRequestId", this.retrieveLinePosition);
  },
  methods: {
    async requestQueuePosition() {
      const eventId = this.$store.state.eventId;
      if (!eventId) {
        this.$router.push({ name: "404" });
      }
      const resource = `${this.$store.state.publicApiUrl}/assign_queue_num`;
      const body = {
        event_id: eventId,
      };
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().post(resource, body);
        store.commit("setRequestId", response.data.api_request_id);
      } catch (error) {
        console.log(error);
        component.entryFailed = true;
      }
    },
    async retrieveLinePosition() {
      const eventId = this.$store.state.eventId;
      const requestId = this.$store.state.requestId;
      const resource = `${this.$store.state.publicApiUrl}/queue_num?event_id=${eventId}&request_id=${requestId}`;
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().get(resource);
        store.commit(
          "setMyPosition",
          Number.parseInt(response.data.queue_number)
        );
      } catch (error) {
        console.log(error);
        component.entryFailed = true;
      }
    },
  },
};
</script>
