<template>
  <div>
    <div v-if="!apiFailed">{{ waitingRoomSize }} people are waiting <br /></div>
    <div v-if="apiFailed">An error occurred while updating</div>
  </div>
</template>

<script>
import axiosClient from "../utils/axios";

import { mixin as VueTimers } from "vue-timers";
const UPDATE_INTERVAL_MS = 3000;
export default {
  name: "WaitingRoomSize",
  mixins: [VueTimers],
  // use a vue timer for periodic updates
  timers: {
    updateWaitingRoomSite: {
      time: UPDATE_INTERVAL_MS,
      autostart: true,
      repeat: true,
      immediate: true,
    },
  },
  // stop and restart the timers if we leave and re-enter this view
  unmounted() {
    if (this.timers.updateWaitingRoomSite.isRunning) {
      this.$timer.stop("updateWaitingRoomSite");
    }
  },
  mounted() {
    if (!this.timers.updateWaitingRoomSite.isRunning) {
      this.$timer.start("updateWaitingRoomSite");
    }
  },
  data() {
    // track local state on the component
    return {
      apiFailed: false,
      waitingRoomSize: 0,
    };
  },
  methods: {
    async updateWaitingRoomSite() {
      const eventId = this.$store.state.eventId;
      const resource = `${this.$store.state.publicApiUrl}/waiting_num?event_id=${eventId}`;
      const component = this;
      try {
        const response = await axiosClient().get(resource);
        component.apiFailed = false;
        component.waitingRoomSize = Number.parseInt(response.data.waiting_num);
      } catch (error) {
        console.log(error);
        component.apiFailed = true;
        component.$timer.stop("updateWaitingRoomSite");
      }
    },
  },
};
</script>
