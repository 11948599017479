<template>
  <div>
    <div v-if="!apiFailed && queuePosition">
      Counter {{ queuePosition }} and lower are being served
    </div>
    <div v-if="apiFailed">An error occurred while updating</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin as VueTimers } from "vue-timers";
import axiosClient from "@/utils/axios";
const UPDATE_INTERVAL_MS = 3000;
export default {
  name: "ServingPosition",
  mixins: [VueTimers],
  // use a vue timer for periodic updates
  timers: {
    updateServingPosition: {
      time: UPDATE_INTERVAL_MS,
      autostart: true,
      repeat: true,
      immediate: true,
    },
  },
  data() {
    return {
      apiFailed: false,
    };
  },
  // stop the timer when we leave this component
  unmounted() {
    if (this.timers.updateServingPosition.isRunning) {
      this.$timer.stop("updateServingPosition");
    }
  },
  // start the timer when we re-enter this component
  mounted() {
    if (!this.timers.updateServingPosition.isRunning) {
      this.$timer.start("updateServingPosition");
    }
  },
  computed: {
    ...mapGetters(["hasRequestId", "hasQueuePosition", "hasToken"]),
    requestId() {
      return this.$store.state.requestId;
    },
    myPosition() {
      return this.$store.state.myPosition;
    },
    queuePosition() {
      return this.$store.state.queuePosition;
    },
  },
  methods: {
    updateServingPosition() {
      // include the event ID as a parameter on the request
      const eventId = this.$store.state.eventId;
      const resource = `${this.$store.state.publicApiUrl}/serving_num?event_id=${eventId}`;
      const store = this.$store;
      const component = this;
      axiosClient()
        .get(resource)
        .then(function (response) {
          component.apiFailed = false;
          store.commit(
            "setQueuePosition",
            Number.parseInt(response.data.serving_counter)
          );
        })
        .catch(function (error) {
          console.log(error);
          component.apiFailed = true;
          component.$timer.stop("updateServingPosition");
        });
    },
  },
};
</script>
