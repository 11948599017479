import axios from "axios";
import axiosRetry from "axios-retry";

const maxApiRetries = 10;

const defaultOptions = {
  retries: maxApiRetries,
  retryDelay: () => {
    return axiosRetry.exponentialDelay;
  },
  retryCondition: function (state) {
    return state.response.status !== 200;
  },
};

function axiosClient(options = defaultOptions) {
  const client = axios.create({
    validateStatus: function (status) {
      return status === 200;
    },
  });
  axiosRetry(client, { ...defaultOptions, ...options });

  return client;
}

export default axiosClient;
