// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

/*jshint esversion: 6 */

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import WaitingRoom from "../views/WaitingRoom.vue";
import PageNotFound from "../views/errors/PageNotFound.vue";

// routes determine which view component to show based on the address

const routes = [
  {
    path: "/",
    alias: "/index.html",
    name: "Home",
    component: Home,
  },
  {
    path: "/waitingroom",
    name: "Waiting Room",
    component: WaitingRoom,
  },
  {
    path: "/404",
    name: "404",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
